import React, { Component } from 'react'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import PageLayout from '../components/layouts/PageLayout'
import { AZFilterList } from '../components/common'
import Breadcrumb from './../components/common/Breadcrumb'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES, JSON_ENTRY_TYPES, SECTIONS } from '../utils/url'

import '../assets/styles/guideline/_browseGuidelines.scss'
import '../assets/styles/_directory.scss'
import * as SearchHelper from '../helpers/SearchHelper'

export const PAGE_SLUG = 'directory-of-services'

export default class DirectoryOfServices extends Component {
    constructor(props) {
        super(props)

        const healthPointServices = get(props, 'data.allHealthPointServices.edges').map(({ node }) => node)
        const externalLinks = get(props, 'data.allContentfulPageDirectoryOfServices.edges').map(({ node }) => {
            return node.links.map(link => ({ name: link.text, url: link.url, external: true }))
        })[0]

        this.state = {
            services: sortBy([...healthPointServices, ...externalLinks], ['name']),
            index: null,
        }
    }

    static getDerivedStateFromProps(props) {
        const queryParams = SearchHelper.queryParamsToObject(props.location.search)

        const index = get(queryParams, 'index')
        return {
            index,
        }
    }


    getSlugFromName = name => {
        return name ? name.toLowerCase().replace(/ /g, '-') : ''
    }

    render() {
        const { services, index } = this.state
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return (
            <PageLayout className="guideline-list directory" activeSection={SECTIONS.VISITOR.key} sitemap={sitemap}>
                <Helmet title="Starship - Directory of Services" />
                <Breadcrumb page={{ title: 'Directory of Services', slug: PAGE_SLUG, __typename: CONTENTFUL_GRAPHQL_TYPES.GENERIC_PAGE, userSection: SECTIONS.VISITOR.key }} />
                <main>
                    <div className="guideline content wrapper">
                        <h2>Directory of Services</h2>
                        <h4>Your A-Z of clinical departments and services.</h4>
                        <AZFilterList
                            items={services}
                            itemKey="name"
                            selectedIndex={index}
                            renderComponent={item => {
                                if (item.external) {
                                    return (
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            {item.name} <i className="far fa-share-square icon" />
                                        </a>
                                    )
                                }
                                return (
                                    <Link
                                        to={buildURL(
                                            {
                                                slug: this.getSlugFromName(item.name),
                                            },
                                            JSON_ENTRY_TYPES.SERVICE
                                        )}
                                    >
                                        {item.name}
                                    </Link>
                                )
                            }}
                        />
                    </div>
                </main>
            </PageLayout>
        )
    }
}

export const pageQuery = graphql`
    query HealthPointServices {
        allHealthPointServices(sort: { fields: [name], order: ASC }) {
            edges {
                node {
                    name
                }
            }
        }
        allContentfulPageDirectoryOfServices {
            edges {
                node {
                    title
                    links {
                        text
                        url
                    }
                }
            }
        }
        allContentfulWidgetSitemap {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageCustom {
                            slug
                            title
                            contentful_id
                            userSection
                        }
                        ... on ContentfulPageDirectoryOfServices {
                            id
                            title
                        }
                        ... on ContentfulPageDonation {
                            slug
                            title
                            contentful_id
                        }
                        ... on ContentfulPageGeneric {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroup {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulPageWidgets {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
